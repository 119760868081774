import '../sass/style.scss';

import { loadPage } from './services/load-page.service.js';

$(async () => {
  const route = location.pathname;

  // Register the components

  // Key: url pathname, Value: field pathname
  const routes = {
    '/': 'apps/Home/home.app.js',

    '/Investigacao/FormularioInvestigacao/FormularioInvestigacao':
      'controllers/form-investigation/form-investigation.controller.js',

    '/Investigacao/PesquisaInvestigacao/PesquisaInvestigacao': 'apps/search-investigation/search-investigation.js',

    '/Investigacao/FormularioModificacaoInvestigacao/FormularioModificacaoInvestigacao':
      'apps/form-modification-investigation/form-modification-investigation.app.js',

    '/Investigacao/Configuracao': 'controllers/gdm_index_controller.js',

    '/Investigacao/RelatorioExecutivo/RelatorioExecutivo': 'apps/Report-Investigation/Report-Investigation.app.js',

    '/Investigacao/RelatorioExecutivo/EditarRelatorio': 'apps/Report-Investigation/Edit-Report-Investigation.app.js',

    '/Permissoes/PermissoesInvestigacao': 'apps/permissions-investigations/permissions-investigations.app.js',

    '/Permissoes/GestaoPessoasInvestigacao': 'apps/permissions-investigations/people-management-investigations.app.js',

    '/Permissoes/PermissoesFinanceiro': 'apps/permissions-financial/permissions-financial.app.js',

    '/Investigacao/DashBoardInicialInvestigacao/DashBoardInicialInvestigacao':
      'apps/dashboard-investigation/dashboard-investigation.app.js',

    '/Investigacao/Integracoes/Integracoes': 'apps/integrations/integrations.app.js',

    '/Certificacao/CriarCertificacao': 'apps/create-certifications/certifications.app.js',
    '/Certificacao/EditarCertificacao': 'apps/create-certifications/EditCertification.app.js',
    '/Certificacao/PesquisaCertificacao': 'apps/search-certifications/search-certifications.app.js',
    '/Certificacao/PermissoesCertificacao': 'apps/permissions-certifications/permissions-certifications.app.js',

    '/Certificacao/MetasCertificacao': 'apps/metas-certifications/metas-certifications.app.js',

    '/Financeiro/DashBoardInicialFinanceiro/DashBoardInicialFinanceiro':
      'apps/dashboard-financial/dashboard-financial.app.js',
  };

  if (routes[route]) {
    const app = await import('./' + routes[route]);

    await app.default();
  }

  loadPage();
});
